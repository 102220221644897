<template>
  <div class="s-flat-box">
    <div class="s-plr-16">
      <h2>{{ $t("login.title") }}</h2>
      <p>{{ $t("login.subTitle") }}</p>
      <div>
        <Input
          v-model="username"
          class="s-mt-50"
          label="Email"
          :error="error.username"
          placeholder="johndoe@email.com"
        ></Input>
        <Input
          v-model="password"
          class="s-mt-30"
          :error="error.password"
          :label="$t('login.password')"
          placeholder="*********"
          type="password"
          @keypress.enter="logins()"
        >
          <template #rightBottomField>
            <router-link
              :to="
                $translate({
                  name: 'authentication',
                  params: { authType: 'forgot-password' },
                })
              "
            >
              <p class="s-text-primary s-weight-600">
                {{ $t("login.forgotPassword") }}
              </p>
            </router-link>
          </template>
        </Input>
      </div>
      <div class="s-fullWidth s-mt-30">
        <Button
          @click.prevent="logins()"
          class="s-fullWidth"
          :isLoading="isLoading"
          >{{ $t("login.login") }}</Button
        >
      </div>
    </div>
    <hr class="s-line-primary s-mtb-16" />
    <div class="s-plr-16">
      <div class="s-fullWidth s-mt-30">
        <router-link
          :to="
            $translate({
              name: 'authentication',
              params: { authType: 'create-account' },
            })
          "
        >
          <Button class="s-fullWidth" color="secondary">{{
            $t("login.createAccount")
          }}</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [form],
  data: () => ({
    isLoading: false,
    username: "",
    password: "",
    error: {
      username: false,
      password: false,
    },
  }),
  computed: {
    ...mapGetters({
      status: "auth/getStatus",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async validation() {
      console.log(this.password.length);
      let errorCount = 0;
      if (this.username.length == 0) {
        this.error.username = true;
        errorCount++;
      }
      if (this.password == null || this.password.length == 0) {
        this.error.password = true;
        errorCount++;
      }
      return errorCount;
    },
    async logins() {
      this.isLoading = true;
      if ((await this.validation()) == 0) {
        const data = {
          email: this.username,
          password: this.password,
        };

        await this.login(data);
        if (this.$store.getters["auth/getStatus"] == 1) {
          this.$router.push({ path: "/dashboard" });
        } else if (
          this.$store.getters["auth/getMessage"] == "Anda tidak memiliki akses"
        ) {
          this.showSnackbar({
            type: "error",
            text: "Verify your email!",
          });
        } else {
          this.showSnackbar({
            type: "error",
            text: this.$store.getters["auth/getMessage"],
          });
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
